import React, { useEffect, useRef, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import './App.css';

const WIDTH = 1920;
const HEIGHT = 1080;
const SOCKET_URL = process.env.REACT_APP_SOCKET_URL || 'https://seahorse-app-otrqx.ondigitalocean.app';
// Load images
const ghostImage = new Image();
ghostImage.src = 'ghost.png';
const victimImage = new Image();
victimImage.src = 'victim.png';
const deadVictimImage = new Image();
deadVictimImage.src = 'deadVictim.png';
const revealedGhostImage = new Image();
revealedGhostImage.src = 'ghostRevealed.png';
const backgroundImage = new Image();
backgroundImage.src = 'tilebackground.png';
const titleScreenGif = new Image();
titleScreenGif.src = 'titleScreen.gif';
const noBatteryImage = new Image();
noBatteryImage.src = 'no-battery.png';
const batteryImage = new Image();
batteryImage.src = 'battery.png';
const glowstickImage = new Image();
glowstickImage.src = 'glowstick.png';
const teleporterImage = new Image();
teleporterImage.src = 'teleporter.png';
const bombBuddyImage = new Image();
bombBuddyImage.src = 'bombbuddy.png';

const stabSound = new Audio('/stab.mp3'); //Sounds
const shriekSound = new Audio('/shriek.mp3');
const chargeSound = new Audio('/charge.mp3');
chargeSound.volume = 0.5;
const reviveSound = new Audio('/revive.mp3');
const teleportSound = new Audio('/teleport.mp3');
const explosionSound = new Audio('/explosion.mp3');
//chargeSound.loop = true; // Set the charge sound to loop
const menuMusic = new Audio('/sans.mp3'); //music for game
const gameMusic = new Audio('/serenadeofwater.mp3');


interface PlayerInfo {
  id: string;
  color: string;
}

interface Player {
  id: string;
  location: {
    x: number;
    y: number;
  };
  batteryStored: number;
  flashlightActive: boolean;
  directionMoving: string;
  lookingAngle: number;
  revivePercentage: number;
  ability: string;
  abilityCooldown: number;
}

interface Battery {
  x: number;
  y: number;
}

interface Wall {
  id: number;
  start: {
    x: number;
    y: number;
  };
  end: {
    x: number;
    y: number;
  };
}

interface BatteryDrainGlowstickBombBuddyEntity {
  type: string;
  x: number;
  y: number;
  power: number;
}

interface GlowstickEntity {
  type: 'glowstick';
  x: number;
  y: number;
  owner: string;
}

interface TeleporterEntity {
  type: 'teleporter';
  x: number;
  y: number;
  owner: string;
}

type Entity = BatteryDrainGlowstickBombBuddyEntity | GlowstickEntity | TeleporterEntity;

interface GameState {
  ghost: Ghost;
  ghostHealth: number;
  victims: Player[];
  gameClock: number;
  Entities: Entity[];
  batteries: Battery[];
}

interface Ghost {
  type: 'Ghost';
  id: string;
  location: {
    x: number;
    y: number;
  };
  directionMoving: string;
  revealedTimer: number;
  sprintingActive: boolean;
  abilityCooldown: number;
  abilityChargeUp: number;
  abilityActive: boolean;
}

const ghostAbilities = [
  { name: 'Bedtime', image: 'bedtime.png' },
  { name: 'Bomb Buddy', image: 'bombbuddy.png' },
  { name: 'Landmine', image: 'landmine.png' },
];

const victimAbilities = [
  { name: 'Glowstick', image: 'glowstick.png' },
  { name: 'Teleporter', image: 'teleporter.png' },
  { name: 'Insta Revive', image: 'instarevive.webp' },
];

function App() {
  const socketRef = useRef<Socket | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [roomId, setRoomId] = useState<string>('');
  const [player, setPlayer] = useState<PlayerInfo | null>(null);
  const [gameState, setGameState] = useState<GameState | null>(null);
  const [keysPressed, setKeysPressed] = useState<{ [key: string]: boolean }>({});
  const [currentDirection, setCurrentDirection] = useState<string>('stand');
  const lastSentDirectionRef = useRef<string>('stand'); //dont send direction multiple times
  const [numPlayers, setNumPlayers] = useState<number>(2); // Default to 2 players
  const [roomInput, setRoomInput] = useState<string>('');
  const [gameStarted, setGameStarted] = useState<boolean>(false);
  const chargeSoundRef = useRef<HTMLAudioElement>(chargeSound);//Sound for chargeup
  const [selectedAbility, setSelectedAbility] = useState<string | null>(null); //These 3 for ability selection
  const [showAbilitySelection, setShowAbilitySelection] = useState<boolean>(false);
  const [isCreatingRoom, setIsCreatingRoom] = useState<boolean>(false);
  const [gameTimer, setGameTimer] = useState<number>(300);

  const [walls, setWalls] = useState<Wall[]>([]);

  //Music
  const menuMusicRef = useRef(menuMusic);
  const gameMusicRef = useRef(gameMusic);
  useEffect(() => {
    const menuMusicAudio = menuMusicRef.current;
    const gameMusicAudio = gameMusicRef.current;

    menuMusicAudio.loop = true;
    gameMusicAudio.loop = true;

    if (!roomId) {
      // Play menu music when on the title screen
      menuMusicAudio.play().catch(error => console.error('Error playing menu music:', error));
      gameMusicAudio.pause();
      gameMusicAudio.currentTime = 0;
    } else if (gameStarted) {
      // Play game music when the game has started
      gameMusicAudio.play().catch(error => console.error('Error playing game music:', error));
      menuMusicAudio.pause();
      menuMusicAudio.currentTime = 0;
    }

    return () => {
      menuMusicAudio.pause();
      gameMusicAudio.pause();
    };
  }, [roomId, gameStarted]);

  useEffect(() => {
    socketRef.current = io(SOCKET_URL);
    socketRef.current.on('connect', () => console.log('Connected to server.'));

    socketRef.current.on('roomCreated', (data: { roomId: string; playerInfo: PlayerInfo }) => {
      console.log('Room created with ID:', data.roomId);
      setRoomId(data.roomId);
      setPlayer(data.playerInfo);
    });

    socketRef.current.on('wallData', (wallData: Wall[]) => {
      setWalls(wallData);
    });

    socketRef.current.on('playerInfo', (playerInfo: PlayerInfo) => {
      console.log('Player info received:', playerInfo);
      setPlayer(playerInfo);
    });

    socketRef.current.on('gameStateUpdate', (newGameState) => {
      //console.log('Game state updated:', newGameState);
      setGameState(newGameState);
      setGameStarted(true);
      // Update game timer
      const elapsedSeconds = Math.floor(newGameState.gameClock / 30); // Assuming 30 ticks per second
      const remainingSeconds = Math.max(300 - elapsedSeconds, 0);
      setGameTimer(remainingSeconds);
    });

    socketRef.current.on('error', (errorMessage) => {
      console.error('Error:', errorMessage);
    });

    // Alert with 'gameOver' message
    socketRef.current.on('gameOver', ({ message }) => {
      window.location.reload();
      alert(message);
    });

    socketRef.current.on('soundEffect', ({ message }) => {
      console.log('Sound effect received:', message);
      if (message === 'stab') {
        stabSound.play().catch(error => console.error('Error playing stab sound:', error));
      } else if (message === 'shriek') {
        shriekSound.play().catch(error => console.error('Error playing shriek sound:', error));
      } else if (message === 'revive') {
        reviveSound.play().catch(error => console.error('Error playing revive sound:', error));
      } else if (message === 'teleport') {
        teleportSound.play().catch(error => console.error('Error playing teleport sound:', error));
      } else if (message === 'explosion') {
        explosionSound.play().catch(error => console.error('Error playing explosion sound:', error)); 
      }
    });

    return () => {
      socketRef.current?.disconnect();
    };
  }, []);

  useEffect(() => {
    if (gameState && gameState.ghost) {
      if (gameState.ghost.abilityChargeUp > 0) {
        if (chargeSoundRef.current.paused) {
          chargeSoundRef.current.play().catch(error => console.error('Error playing charge sound:', error));
        }
      } else {
        chargeSoundRef.current.pause();
        chargeSoundRef.current.currentTime = 0;
      }
    }
  }, [gameState]);


  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    if (!canvas || !ctx || !gameState) return;

    canvas.width = WIDTH;
    canvas.height = HEIGHT;

    // Clear the canvas
    ctx.clearRect(0, 0, WIDTH, HEIGHT);

    if (backgroundImage.complete) {
      const pattern = ctx.createPattern(backgroundImage, 'repeat');
      if (pattern) {
        ctx.fillStyle = pattern;
        ctx.fillRect(0, 0, WIDTH, HEIGHT);
      }
    }
    ctx.fillStyle = 'rgba(255, 255, 255, 0.3)';
    ctx.fillRect(0, 0, WIDTH, HEIGHT);

    // Draw batteryDrain entities
    gameState.Entities.forEach(entity => {
      if (entity.type === 'batteryDrain') {
        ctx.beginPath();
        ctx.arc(entity.x, entity.y, 100, 0, Math.PI * 2);
        ctx.strokeStyle = entity.power > 100 ? 'red' : 'purple';
        ctx.lineWidth = 2;
        ctx.stroke();

        // Draw inner growing circle
        const innerRadius = Math.min(entity.power, 100);
        ctx.beginPath();
        ctx.arc(entity.x, entity.y, innerRadius, 0, Math.PI * 2);
        ctx.fillStyle = entity.power > 100 ? 'rgba(255, 0, 0, 0.2)' : 'rgba(128, 0, 128, 0.1)';
        ctx.fill();
      } else if (entity.type === 'glowstick') {
        ctx.drawImage(glowstickImage, entity.x - 15, entity.y - 15, 20, 20);
      } else if (entity.type === 'teleporter') {
        ctx.drawImage(teleporterImage, entity.x - 20, entity.y - 20, 40, 40);
      }else if (entity.type === 'bombBuddy') {
        ctx.drawImage(bombBuddyImage, entity.x - 20, entity.y - 20, 40, 40);
        
        // Draw power indicator
        ctx.fillStyle = `rgba(255, 0, 0, ${entity.power / 300})`;
        ctx.beginPath();
        ctx.arc(entity.x, entity.y, 25, 0, 2 * Math.PI);
        ctx.fill();
        // Draw kill distance indicator
        const killRadius = Math.min(entity.power, 200);
        ctx.beginPath();
        ctx.arc(entity.x, entity.y, killRadius, 0, 2 * Math.PI);
        ctx.strokeStyle = 'rgba(255, 0, 0, 0.5)';
        ctx.lineWidth = 2;
        ctx.stroke();
      }
    });

    // Draw batteries
    gameState.batteries.forEach(battery => {
      ctx.drawImage(batteryImage, battery.x - 30, battery.y - 30, 60, 60);
    });
    const maxDistance = Math.sqrt(WIDTH * WIDTH + HEIGHT * HEIGHT) / 2;
    // Draw Players
    gameState.victims.forEach(victim => {
      const img = victim.revivePercentage < 100 ? deadVictimImage : victimImage;

      // Calculate distance to ghost
      const dx = gameState.ghost.location.x - victim.location.x;
      const dy = gameState.ghost.location.y - victim.location.y;
      const distanceToGhost = Math.sqrt(dx * dx + dy * dy);

      // Calculate red intensity based on proximity, starting from half the max distance
      let redIntensity = 0;
      if (distanceToGhost < maxDistance) {
        redIntensity = Math.min(255, Math.floor(255 * (1 - distanceToGhost / maxDistance) * 2));
      }

      ctx.save();
      ctx.translate(victim.location.x, victim.location.y);

      // Draw red circle
      if (redIntensity > 0) {
        const gradient = ctx.createRadialGradient(0, 0, 0, 0, 0, 50);
        gradient.addColorStop(0, `rgba(255, 0, 0, ${redIntensity / 255 * 0.7})`);
        gradient.addColorStop(1, 'rgba(255, 0, 0, 0)');

        ctx.beginPath();
        ctx.arc(0, 0, 50, 0, Math.PI * 2);
        ctx.fillStyle = gradient;
        ctx.fill();
      }

      // Draw victim
      ctx.rotate(victim.lookingAngle * Math.PI / 180);
      ctx.drawImage(img, -25, -25, 50, 50);

      ctx.restore();

      if (victim.batteryStored <= 0) {
        const symbolSize = 30; // Adjust this value to change the size of the symbol
        ctx.drawImage(noBatteryImage,
          victim.location.x - symbolSize / 2,
          victim.location.y - 35 - symbolSize / 2,
          symbolSize,
          symbolSize
        );
      }

      if (victim.flashlightActive && victim.batteryStored > 0 && victim.revivePercentage >= 100) {
        const angleWidth = 40;
        const flashlightRange = (victim.batteryStored / 10) + 45;
        const angleRad = victim.lookingAngle * Math.PI / 180;
        const halfAngleRad = angleWidth * Math.PI / 180 / 2;

        const x1 = victim.location.x;
        const y1 = victim.location.y;
        const x2 = x1 + flashlightRange * Math.cos(angleRad - halfAngleRad);
        const y2 = y1 + flashlightRange * Math.sin(angleRad - halfAngleRad);
        const x3 = x1 + flashlightRange * Math.cos(angleRad + halfAngleRad);
        const y3 = y1 + flashlightRange * Math.sin(angleRad + halfAngleRad);

        // Draw blurred glow effect
        ctx.save();
        ctx.filter = 'blur(20px)';
        ctx.fillStyle = 'rgba(255, 255, 100, 0.3)';
        ctx.beginPath();
        ctx.moveTo(x1, y1);
        ctx.arc(x1, y1, flashlightRange, angleRad - halfAngleRad, angleRad + halfAngleRad);
        ctx.closePath();
        ctx.fill();
        ctx.restore();

        // Draw original flashlight cone with filled arc
        ctx.fillStyle = 'rgba(255, 255, 100, 0.5)';
        ctx.beginPath();
        ctx.moveTo(x1, y1);
        ctx.arc(x1, y1, flashlightRange, angleRad - halfAngleRad, angleRad + halfAngleRad);
        ctx.closePath();
        ctx.fill();

        // Draw the arc outline
        ctx.strokeStyle = 'rgba(255, 255, 100, 0.8)';
        ctx.lineWidth = 4;
        ctx.beginPath();
        ctx.arc(x1, y1, flashlightRange, angleRad - halfAngleRad, angleRad + halfAngleRad);
        ctx.stroke();
      }

      // Draw revive percentage if victim is "dead"
      if (victim.revivePercentage < 100) {
        ctx.fillStyle = 'white';
        ctx.font = '12px Arial';
        const roundedPercentage = Math.round(victim.revivePercentage);
        ctx.fillText(`${roundedPercentage}%`, victim.location.x - 15, victim.location.y + 35);
      }
    });

    // Draw ghost
    if ((player && player.id === 'Player1') || gameState.ghost.revealedTimer > 0 || gameState.ghost.sprintingActive || gameState.ghost.abilityChargeUp > 0) {
      const img = gameState.ghost.revealedTimer > 0 ? revealedGhostImage : ghostImage;

      // Set transparency based on ghost state
      if (gameState.ghost.revealedTimer > 0 || gameState.ghost.sprintingActive || gameState.ghost.abilityChargeUp > 0) {
        ctx.globalAlpha = 1; // Fully opaque when revealed or sprinting
      } else {
        ctx.globalAlpha = 0.7; // Half transparent otherwise
      }

      ctx.drawImage(img, gameState.ghost.location.x - 25, gameState.ghost.location.y - 25, 50, 50);

      // Reset globalAlpha to 1 for other drawings
      ctx.globalAlpha = 1;

      // Draw ability charge-up circle
      if (gameState.ghost.abilityChargeUp > 0) {
        ctx.beginPath();
        ctx.arc(gameState.ghost.location.x, gameState.ghost.location.y, 30, 0, (gameState.ghost.abilityChargeUp / 120) * Math.PI * 2);
        ctx.strokeStyle = 'purple';
        ctx.lineWidth = 3;
        ctx.stroke();

        // Draw full circle when fully charged
        if (gameState.ghost.abilityChargeUp >= 210) {
          ctx.beginPath();
          ctx.arc(gameState.ghost.location.x, gameState.ghost.location.y, 30, 0, Math.PI * 2);
          ctx.stroke();
        }
      }

      if (gameState.ghost.revealedTimer > 20) { //yellow circle
        // Create a yellow gradient circle
        const gradient = ctx.createRadialGradient(
          gameState.ghost.location.x, gameState.ghost.location.y, 0,
          gameState.ghost.location.x, gameState.ghost.location.y, 40
        );
        gradient.addColorStop(0, 'rgba(255, 255, 0, 0.8)');  // Bright yellow at the center
        gradient.addColorStop(0.7, 'rgba(255, 255, 0, 0.3)');  // Faded yellow
        gradient.addColorStop(1, 'rgba(255, 255, 0, 0)');  // Transparent at the edge

        ctx.fillStyle = gradient;
        ctx.beginPath();
        ctx.arc(gameState.ghost.location.x, gameState.ghost.location.y, 40, 0, 2 * Math.PI);
        ctx.fill();
        //Text for reveal
        ctx.fillStyle = 'yellow';
        ctx.font = '14px Arial';
        ctx.fillText(`${Math.ceil((gameState.ghost.revealedTimer-20)/ 30)}s`, gameState.ghost.location.x - 10, gameState.ghost.location.y - 40);
      }
    }

    // Display ghost health
    ctx.fillStyle = 'white';
    ctx.font = '20px Arial';
    ctx.fillText(`Ghost Health: ${gameState.ghostHealth}`, 10, 30);

    // Draw walls
    walls.forEach(wall => {
      ctx.strokeStyle = 'black';
      ctx.lineWidth = 10;
      ctx.beginPath();
      ctx.moveTo(wall.start.x * 60, wall.start.y * 60);
      ctx.lineTo(wall.end.x * 60, wall.end.y * 60);
      ctx.stroke();
    });
    walls.forEach(wall => {
      ctx.strokeStyle = 'white';
      ctx.lineWidth = 3;
      ctx.beginPath();
      ctx.moveTo(wall.start.x * 60, wall.start.y * 60);
      ctx.lineTo(wall.end.x * 60, wall.end.y * 60);
      ctx.stroke();
    });
    // Draw game timer
    ctx.fillStyle = 'white';
    ctx.font = '28px Arial';
    const minutes = Math.floor(gameTimer / 60);
    const seconds = gameTimer % 60;
    ctx.fillText(`Time: ${minutes}:${seconds.toString().padStart(2, '0')}`, 875, 30);

    // Draw ability cooldown timer
    if (player) {
      const currentPlayer = gameState.victims.find(v => v.id === player.id) ||
        (player.id === 'Player1' ? gameState.ghost : null);
      if (currentPlayer && 'abilityCooldown' in currentPlayer) {
        const cooldown = currentPlayer.abilityCooldown;
        if (cooldown > 0) {
          ctx.fillStyle = 'yellow';
          ctx.font = '20px Arial';
          ctx.fillText(`Ability Cooldown: ${cooldown}`, 200, 30);
        }
      }
    }
  }, [gameState, walls]);

  const handleKeyDown = (event: KeyboardEvent) => {
    setKeysPressed(prev => {
      const newKeys = { ...prev, [event.code]: true };
      updateDirection(newKeys);
      // Check for flashlight toggle on
      if (event.code === 'KeyI' && !prev['KeyI']) {  // Ensure it doesn't repeat if key is held down
        console.log(player);
        if (player?.id === 'Player1') {
          socketRef.current?.emit('sprint', true);
          console.log('SPRINTING');
        } else {
          socketRef.current?.emit('flashlight', true);
          console.log('FLASHLIGHTING');
        }
      }
      if (event.code === 'KeyO' && !prev['KeyO']) { // Ability activation
        console.log(player);
        if (player?.id === 'Player1') {
          socketRef.current?.emit('ghostAbilityActive', true);
          console.log('GHOST ABILITY ACTIVATED');
        } else {
          socketRef.current?.emit('victimAbilityActive', true);
          console.log('VICTIM ABILITY ACTIVATED');
        }
      }
      // New code for 'P' key press
      if (event.code === 'KeyP' && !prev['KeyP']) { // Ability 2 activation
        console.log(player);
        if (player?.id === 'Player1') {
          socketRef.current?.emit('ghostAbility2Active', true);
          console.log('GHOST ABILITY 2 ACTIVATED');
        } else {
          socketRef.current?.emit('victimAbility2Active', true);
          console.log('VICTIM ABILITY 2 ACTIVATED');
        }
      }
      return newKeys;
    });
  };

  const handleKeyUp = (event: KeyboardEvent) => {
    setKeysPressed(prev => {
      const newKeys = { ...prev, [event.code]: false };
      updateDirection(newKeys);
      // Check for flashlight toggle off
      if (event.code === 'KeyI') {
        console.log(player);
        if (player?.id === 'Player1') {
          socketRef.current?.emit('sprint', false);
          console.log('SPRINTING OFF');
        } else {
          socketRef.current?.emit('flashlight', false);
          console.log('FLASHLIGHTING OFF');
        }
      }
      if (event.code === 'KeyO') {
        console.log(player);
        if (player?.id === 'Player1') {
          socketRef.current?.emit('ghostAbilityActive', false);
        } else {
          socketRef.current?.emit('victimAbilityActive', false);
        }
      }
      // New code for 'P' key release
      if (event.code === 'KeyP') {
        console.log(player);
        if (player?.id === 'Player1') {
          socketRef.current?.emit('ghostAbility2Active', false);
        } else {
          socketRef.current?.emit('victimAbility2Active', false);
        }
      }
      return newKeys;
    });
  };

  const updateDirection = (keysPressed: { [key: string]: boolean }) => {
    const directionMap = {
      'KeyW': keysPressed['KeyW'],
      'KeyS': keysPressed['KeyS'],
      'KeyA': keysPressed['KeyA'],
      'KeyD': keysPressed['KeyD']
    };

    let vertical = directionMap['KeyW'] && !directionMap['KeyS'] ? 'north' : !directionMap['KeyW'] && directionMap['KeyS'] ? 'south' : '';
    let horizontal = directionMap['KeyD'] && !directionMap['KeyA'] ? 'east' : !directionMap['KeyD'] && directionMap['KeyA'] ? 'west' : '';

    let direction = '';
    if (vertical && horizontal) {
      direction = vertical + horizontal; // Combine like 'northEast'
    } else if (vertical) {
      direction = vertical;
    } else if (horizontal) {
      direction = horizontal;
    } else {
      direction = 'stand';
    }

    // Compare the current direction with a new direction, including trimming any potential extra spaces
    if (direction !== lastSentDirectionRef.current) {
      console.log(`Direction changed from ${lastSentDirectionRef.current} to ${direction}`);
      setCurrentDirection(direction);
      lastSentDirectionRef.current = direction;
      socketRef.current?.emit('move', direction);
    }
  };


  useEffect(() => {
    if (gameStarted) {
      console.log('Adding keyboard event listeners');
      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('keyup', handleKeyUp);

      return () => {
        console.log('Removing keyboard event listeners');
        window.removeEventListener('keydown', handleKeyDown);
        window.removeEventListener('keyup', handleKeyUp);
      };
    }
  }, [gameStarted]);

  const handleCreateRoom = (numPlayers: number) => {
    setIsCreatingRoom(true);
    setShowAbilitySelection(true);
  };

  const handleJoinRoom = (id: string) => {
    setIsCreatingRoom(false);
    setShowAbilitySelection(true);
  };

  const handleAbilitySelection = (ability: string) => {
    setSelectedAbility(ability);
    if (isCreatingRoom) {
      socketRef.current?.emit('createRoom', numPlayers, ability);
    } else {
      socketRef.current?.emit('joinRoom', roomInput, ability);
    }
    setRoomId(isCreatingRoom ? 'waiting' : roomInput);
    setShowAbilitySelection(false);
  };

  return (
    <div className="App" style={{
      backgroundImage: !roomId ? `url(${titleScreenGif.src})` : 'none',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      {!roomId && !showAbilitySelection && (
        <div style={{
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          padding: '20px',
          borderRadius: '10px',
          color: 'white'
        }}>
          <h1>Create or Join a Game</h1>
          <select onChange={e => setNumPlayers(parseInt(e.target.value, 10))}>
            {[2, 3, 4, 5].map(n => (
              <option key={n} value={n}>{n} Players</option>
            ))}
          </select>
          <button onClick={() => handleCreateRoom(numPlayers)}>Create Room</button>
          <input
            type="text"
            placeholder="Enter Room ID"
            value={roomInput}
            onChange={e => setRoomInput(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter' && roomInput) {
                handleJoinRoom(roomInput);
              }
            }}
            autoFocus
          />
          <button onClick={() => handleJoinRoom(roomInput)}>Join Room</button>
        </div>
      )}
      {showAbilitySelection && (
        <div style={{
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          padding: '20px',
          borderRadius: '10px',
          color: 'white'
        }}>
          <h2>{isCreatingRoom ? 'Select Ghost Ability' : 'Select Victim Ability'}</h2>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            {(isCreatingRoom ? ghostAbilities : victimAbilities).map((ability) => (
              <div key={ability.name} onClick={() => handleAbilitySelection(ability.name)} style={{ cursor: 'pointer', textAlign: 'center' }}>
                <img src={ability.image} alt={ability.name} style={{ width: '100px', height: '100px' }} />
                <p>{ability.name}</p>
              </div>
            ))}
          </div>
        </div>
      )}
      {roomId && player && (
        <div id="game-container" style={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          {!gameStarted && (
            <div style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 10,
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              color: 'white',
              padding: '20px',
              borderRadius: '10px',
              fontSize: '36px',
              fontWeight: 'bold'
            }}>
              {player.id} - Waiting For Players - Room ID: {roomId}
            </div>
          )}
          <canvas
            ref={canvasRef}
            width={WIDTH}
            height={HEIGHT}
            id="game-canvas"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain'
            }}
          ></canvas>
          {gameState && gameState.gameClock < 1000 && (
            <div style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              color: 'white',
              padding: '10px 15px',
              borderRadius: '15px',
              fontSize: '18px',
            }}>
              <p style={{ margin: 0 }}>Movement Buttons: WASD</p>
              <p style={{ margin: 0 }}>Abilities Buttons: I,O,P</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default App;
